.status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 12px;
  margin: -24px -24px 24px -24px;
  border-bottom: 1px solid #ccc;

  & > div {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }

  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 8px;
  }

  .badge {
    background-color: #8ac149;
    padding: 0px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .reference {
    font-weight: 300;
    margin-right: 4px;
  }

  .reference-number {
    font-weight: 500;
  }
}
