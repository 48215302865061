.form {
  display: flex;
  flex-direction: column;
}

.row {
  & > div {
    margin-bottom: 16px;
  }
}

.sectionTitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.fieldWrapper {
  width: 49%;
}

.checkboxFieldWrapper {
  display: flex;
  margin-bottom: 27px !important;
  font-size: 14px;

  input[type="checkbox"] {
    margin-right: 8px;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

  label {
    cursor: pointer;
  }

  .link {
    display: inline;
    color: #438ded;
    text-decoration: none;
  }
}

.error {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.3px;
  margin-top: 8px;
  color: #e53e3e;
}

.helperText {
  margin-top: 4px;
  align-self: flex-end;
  font-size: 12px;
  color: #808285;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.selectContainer {
  position: relative;

  label {
    position: absolute;
    height: 52px;
    top: 0;
    bottom: 0;
    left: 16px;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: grey;
    font-family: plutoSans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  select:focus + label,
  select + label.selected {
    transform: translate(0, -12px);
    color: #414042;
    font-size: 10px;
  }

  .error {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.3px;
    margin-top: 8px;
    color: #e53e3e;
    padding-left: 1px;
  }
}
