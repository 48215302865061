.header {
  height: 40px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 12px;
  margin: -12px -12px 12px;
  font-size: 14px;
  font-weight: 700;

  button {
    background: transparent;
    border: none;
    padding: 0;
  }
}

.title {
  margin-left: 8px;
}
