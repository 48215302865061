.wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #00000026;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    height: 60px;
    width: 60px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 60px;
  }
}
