.video-banner {
  padding: 0;

  &.video1 {
    background: url("../../assets/images/video1.jpg") no-repeat;
    background-position: center;
    background-size: cover;
  }

  &.video2 {
    background: url("../../assets/images/video2.jpg") no-repeat;
    background-position: center;
    background-size: cover;
  }

  &.video3 {
    background: url("../../assets/images/video3.jpg") no-repeat;
    background-position: center;
    background-size: cover;
  }

  .overlay {
    background: rgba(0, 0, 0, 0.3);
    padding: 40px 0 32px;
  }

  h2 {
    color: $white;
    font-weight: 400;
    font-size: 24px;

    @include media-breakpoint-up(lg) {
      font-size: 44px;
    }
  }

  .btn {
    color: $white;
    font-weight: 370;
    text-decoration: none;
    padding: 0 9px;
    font-size: 20px;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }

    &:hover,
    &:focus {
      color: $white;
    }

    img {
      width: 20px;
      margin: -2px 0 0 6px;

      @include media-breakpoint-up(lg) {
        width: 40px;
        margin: 0 0 0 9px;
      }
    }
  }
}
