.listItem {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  color: #000;

  .listImage {
    display: block;
    margin-right: 6px;
  }
}

.applyButton {
  align-self: flex-end;
  width: fit-content;
}
