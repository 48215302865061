.index {
  .hero-section {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0%) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      radial-gradient(50% 50% at 50% 50%, #d0012d 0%, #39001f 100%);
  }

  .top-content {
    .content-container {
      @include media-breakpoint-up(lg) {
        margin-left: 13px;
      }
    }
  }

  .vans {
    background: rgba(0, 0, 0, 0.05);
    padding: 32px 0;

    @include media-breakpoint-up(lg) {
      padding: 76px 0 86px;
    }

    h1 {
      text-align: center;
    }

    p {
      font-weight: 370;
      text-align: left;
      margin: 20px auto 0;
      line-height: 1.3;

      @include media-breakpoint-up(lg) {
        width: 67%;
      }
    }

    .car-content {
      margin: 130px auto 0;

      @include media-breakpoint-up(lg) {
        width: 91%;
        margin: 170px auto 0;
      }
    }

    .content {
      padding: 30px;
      background: linear-gradient(180deg, #828282 0%, #757575 18.23%);
      box-shadow: 0 4px 7px 1px rgba(0, 0, 0, 0.2);
      border-radius: 22px;
      height: 192px;
      font-weight: 400;
      font-size: 34px;
      color: $white;
      text-transform: capitalize;
      text-align: left;
      display: flex;
      align-items: flex-end;
      line-height: 1;
      position: relative;
      max-width: 326px;
      width: 100%;
      margin: 0 auto 150px;

      @include media-breakpoint-up(lg) {
        margin: 0 auto;
      }
    }

    img {
      position: absolute;
      left: 13px;
      max-width: 300px;
      width: 90%;
      top: -130px;

      @include media-breakpoint-up(sm) {
        top: -150px;
      }

      @include media-breakpoint-up(lg) {
        top: -130px;
      }

      @include media-breakpoint-up(xl) {
        top: -150px;
      }
    }

    .car3 .content {
      margin-bottom: 0;
    }
  }
}
