.errorImg {
  margin: 0 auto 24px;
}

.contentWrapper {
  text-align: center;
}

.title {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: 500;
}

.description {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 24px;
}
