.contact-form {
  margin-top: 30px;

  @media only screen and (min-width: 992px) {
    margin-top: 0;
  }

  label {
    font-style: normal;
    color: #414042;
    letter-spacing: 0.3px;
    font-size: 24px;
    font-weight: 370;
    -webkit-font-smoothing: antialiased;
    margin: 0 0 8px 0;
    line-height: 1.4;
  }

  .form-check-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
    margin: 0;
  }

  .form-control {
    border: 1px solid #808080;
  }

  .form-check {
    margin: 0 12px 20px 0;
  }

  ::placeholder {
    color: #808080;
    font-weight: 370;
    font-size: 16px;
    text-transform: capitalize !important;
  }

  .message-block {
    padding: 10px;
    margin: 0 0 5px 0;
    font-weight: 370;
    text-align: center;
    border: 1px solid transparent;
    min-height: 42px;
    float: left;
    width: 100%;

    &.success {
      color: #198754;
      border: 1px solid #198754;
      background-color: #d1e7dd;
    }

    &.error {
      color: #dc3545;
      border: 1px solid #dc3545;
      background-color: #f8d7da;
    }
  }

  .error-text {
    font-size: 14px;
    font-weight: 370;
    color: #dc0032;
    min-height: 20px;

    &.alignment {
      margin: -17px 0 18px;
    }
  }

  button {
    width: 100%;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      width: auto;
      margin-bottom: 0;
    }
  }

  .btn-row {
    float: left;
    width: 100%;
    display: block;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: initial;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    a {
      padding: 0 0 20px;
      display: inline-block;

      @include media-breakpoint-up(lg) {
        padding: 0;
      }
    }
  }
}
