.my-details {
  border-color: #808080;

  .card-header {
    border-bottom: 0;
    background: white;
    margin-top: 16px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
  }

  .card-body {
    padding-top: 0;
  }

  .data-block {
    margin-top: 16px;

    .label,
    .text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -1px;
    }

    .label {
      color: #414042;
    }

    .text {
      color: #808080;
    }
  }
}
