.centered-layout-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .centered-layout {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 16px;
    background-color: #efedeb;

    @media (max-width: 991px) {
      margin-top: 73px;
    }
  }
}
