// bootstrap overrides
$primary: #dc0032;
$secondary: #414042;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "./assets/scss/variables";
@import "./assets/scss/fonts";
@import "./components/Header/index";
@import "./components/Banner/index";
@import "./components/Cards/index";
@import "./components/HexagonContent/index";
@import "./components/VideoSection/index";
@import "./components/VideoBanner/index";
@import "./components/Strapline/index";
@import "./components/FloatingButton/index";
@import "./components/ChoiceForm/index";
@import "./pages/Index/index";
@import "./pages/Choice/index";
@import "../node_modules/animate.css/animate.css";
@import "../node_modules/react-toastify/dist/ReactToastify.css";

#root {
  position: relative;
}

a {
  color: $primary;
}

body {
  font-family: map-get($font, "pluto");
  font-weight: map-get($font-weight, "regular");
  letter-spacing: 0.3px;
  color: $secondary;
  line-height: 1.2;

  // Toast styling

  /* stylelint-disable-next-line selector-class-pattern */
  .Toastify__toast {
    border: 1px solid #dc0032;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .Toastify__toast-body {
    padding: 12px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .Toastify__toast-theme--error {
    background: #fce6eb;
    color: #414042;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
  }

  .Toastify__toast-container--top-center {
    padding: 75px 17px 0 17px;
  }
}

:root {
  @each $breakpoint, $value in $grid-breakpoints {
    --breakpoint-#{$breakpoint}: #{$value};
  }

  // Toast styling
  --toastify-color-error: #e74c3c;
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 345px;
  --toastify-toast-background: #fce6eb;
  --toastify-font-family: "plutoSans";
  --toastify-text-color-light: #757575;
}

.container {
  height: 100%;
}

hr {
  background: rgba(0, 0, 0, 0.1);
  margin: 18px 0 27px;
}

// headings
h1 {
  font-size: 24px;
  font-weight: map-get($font-weight, "light");
  margin: 0;
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    font-weight: map-get($font-weight, "extraLight");
    font-size: 56px;
  }
}

h2 {
  font-weight: map-get($font-weight, "bold");
  font-size: 30px;

  @include media-breakpoint-up(lg) {
    font-size: 44px;
  }
}

h3 {
  font-weight: map-get($font-weight, "bold");
  letter-spacing: -0.04em;
  margin: 0;
  font-size: 27px;

  @include media-breakpoint-up(lg) {
    font-size: 29px;
  }
}

h4 {
  font-size: 24px;
  font-weight: map-get($font-weight, "light");
}

h5 {
  font-weight: map-get($font-weight, "regular");
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 7px;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
    margin-bottom: 0;
  }
}

h6 {
  color: $primary !important;
  text-transform: uppercase;
  font-weight: map-get($font-weight, "regular");
  font-size: 14px;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

// button
.btn {
  font-weight: map-get($font-weight, "regular");
  font-size: 14px;
  text-transform: capitalize;
  border-radius: 0;
  padding: 11px 9px;
  text-align: center;

  @include media-breakpoint-up(lg) {
    padding: 11px 30px;
  }

  img {
    margin: 0 0 0 9px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 0 14px;
    }
  }
}

.btn-onboarding {
  border-radius: 6px;
  padding: 6px 12px;
  text-transform: none;

  &.btn-primary {
    --bs-btn-bg: #e5444b;
  }

  &[disabled] {
    --bs-btn-disabled-color: #f6958d;
    --bs-btn-disabled-bg: #fce3dc;
    --bs-btn-disabled-border-color: #fce3dc;
    --bs-btn-disabled-opacity: 1;
  }

  &.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #e5444b;
    --bs-btn-border-color: #e5444b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bb2d3b;
    --bs-btn-hover-border-color: #b02a37;
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b02a37;
    --bs-btn-active-border-color: #a52834;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &.btn-link {
    --bs-btn-color: #e5444b;
    --bs-link-color: #e5444b;
    --bs-btn-font-size: 16px;
    --bs-btn-line-height: 26px;
    --bs-btn-font-family: "plutoSans";
  }

  &.btn-outline-danger {
    --bs-btn-color: #e5444b;
    --bs-btn-border-color: #e5444b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #e5444b;
    --bs-btn-hover-border-color: #e5444b;
    --bs-btn-focus-shadow-rgb: 220, 53, 69;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #e5444b;
    --bs-btn-active-border-color: #e5444b;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #e5444b;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #e5444b;
    --bs-gradient: none;
  }
}

section {
  padding: 32px 17px;
  float: left;
  width: 100%;

  @include media-breakpoint-up(lg) {
    padding: 76px 0 86px;
  }
}

.top-content {
  background: $secondary;
  color: $white;
}

ul {
  padding: 0;
}

li {
  font-weight: map-get($font-weight, "light");
  font-size: 15px;
  background: url("./assets/images/tick.svg") no-repeat;
  list-style: none;
  padding: 0 0 0 35px;
  background-size: 19px;
  background-position: 2px 2px;
  line-height: 1.5;
  margin: 0 0 16px;

  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 15px;
  }

  &:last-of-type {
    margin: 0;
  }
}

// modal
.modal {
  &-header {
    border: 0;
  }

  &-body {
    padding-top: 0;

    > div {
      padding: 56.25% 0 0;
      position: relative;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-title {
    font-size: 20px;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }
  }
}

.hero-section {
  height: 290px;

  @media (min-width: 400px) {
    height: 350px;
  }

  @include media-breakpoint-up(sm) {
    height: 450px;
  }

  @include media-breakpoint-up(md) {
    height: 560px;
  }

  @include media-breakpoint-up(lg) {
    height: 685px;
  }

  @include media-breakpoint-up(xl) {
    height: 780px;
  }

  @media (min-width: 1400px) {
    height: 820px;
  }

  @media (min-width: 1450px) {
    height: 880px;
  }

  @media (min-width: 1600px) {
    height: 990px;
  }
}

p.global {
  font-size: 24px;
  font-weight: map-get($font-weight, "light");
  margin-top: 24px;
  margin-bottom: 0;
  line-height: 1.3;
}

// full height image & content section
.image-content {
  padding: 0;
  position: relative;

  .plcholder {
    height: 320px;
    background: url("./assets/images/how-to-apply2.jpg") no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(sm) {
      height: 500px;
    }

    @include media-breakpoint-up(md) {
      height: 692px;
    }

    @include media-breakpoint-up(lg) {
      background-position: top center;
      width: 50%;
      height: 100%;
      position: absolute;
    }
  }

  .content-container {
    background: $white;

    @include media-breakpoint-up(lg) {
      padding: 76px 0 86px;
    }
  }

  .gutter {
    padding: 32px 17px;

    @include media-breakpoint-up(lg) {
      padding: 0 0 0 22.5px;
    }

    &.right {
      @include media-breakpoint-up(lg) {
        padding: 0 48px 0 0;
      }
    }
  }
}

// margins
.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-7 {
  margin-top: 7px;
}

.h-20 {
  min-height: 20px;
}

.form-select {
  padding-top: 16px;
  padding-bottom: 2px;
  padding-left: 16px;
  height: 52px;
  border-color: #c0c0c0;
}

.rbt-menu {
  min-width: 100%;
  width: fit-content !important;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    min-width: 100%;
    // 528px needed here is width of Wizard component and spaces to calculate form width
    max-width: calc(100vw - 528px);
  }

  .dropdown-item {
    width: 100%;
    font-size: 14px;
  }
}
