.video-section {
  background: rgba(0, 0, 0, 0.05);

  h3 {
    font-size: 24px;
    font-weight: map-get($font-weight, "light");

    @include media-breakpoint-up(lg) {
      font-weight: map-get($font-weight, "extraLight");
      font-size: 32px;
      line-height: 1;
    }
  }

  video,
  iframe {
    width: 262px;
    margin: 25px auto 0;
    height: 147px;

    @include media-breakpoint-up(sm) {
      height: 290px;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      height: 391px;
    }

    @include media-breakpoint-up(lg) {
      width: 856px;
      height: 481px;
    }
  }

  .btn-row {
    margin: 20px 0 0;

    a {
      display: inline-block;
    }
  }
}
