.floating-button {
  position: fixed;
  bottom: 0;
  margin: 10px;
  right: 0;

  @include media-breakpoint-up(lg) {
    margin: 30px;
  }
}
