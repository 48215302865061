.choice {
  .hero-section {
    background: url("../../assets/images/banner2.png") no-repeat;
    background-size: cover;
    background-position: center 20px;

    @media only screen and (min-width: 992px) {
      background-position: center bottom;
    }
  }

  .banner .outer-container {
    display: flex;

    .content {
      margin: 0;
    }
  }

  .content-apply {
    .plcholder {
      background: url("../../assets/images/choice-form.png") no-repeat;
      background-size: cover;
      background-position: center top;

      @media only screen and (min-width: 992px) {
        right: 0;
      }
    }

    .gutter{
      padding: 0 17px;

      @media only screen and (min-width: 992px) {
        padding: 0 48px 0 0;
      }
    }
  }

}
