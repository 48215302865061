.wizard {
  height: min-content !important;
  min-width: 430px !important;
  position: relative;
  margin-right: 16px;
  padding-bottom: 8px;

  @media (max-width: 991px) {
    margin-right: 0;
    width: 100%;
    min-width: auto !important;
  }

  .header {
    display: flex;
    background-color: #fff;
    padding: 24px;
    color: #414042;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: normal;
    border-bottom-color: #ccc;
  }

  .item {
    padding: 27px 24px;
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 5px solid transparent;
    border-bottom: 1px solid #ccc;
    position: relative;

    &.disabled {
      .leftIcon,
      .title {
        opacity: 0.4;
      }
    }

    &:last-of-type {
      border-bottom-color: white;
    }

    &.active {
      @media (min-width: 992px) {
        border-right: 5px solid red;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.05) 0%,
            rgba(0, 0, 0, 0.05) 100%
          ),
          #fff;
      }

      .title {
        color: #414042;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: normal;
      margin: 6px 6px 6px 0;
    }

    .editButton {
      display: none;
      border-radius: 4px;
      border-width: 2px;
      padding: 4px 7px;

      @media (max-width: 991px) {
        display: inline-block;
      }
    }

    .leftIcon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .status {
      display: flex;
      align-items: center;

      .statusText {
        font-size: 16px;
        color: #8ac149;
        margin-right: 4px;
        margin-bottom: 0;
        letter-spacing: normal;
        font-weight: 400;
        line-height: 26px;
      }

      .statusIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.navigationOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
