.emailImage {
  max-height: 148px;
  width: fit-content;
}

.contentWrapper {
  margin-bottom: 24px;

  .emailWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
  }

  .errorText {
    text-align: right;
    color: #dc0032;
    min-height: 20px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  .formLabel {
    color: #414042;
    font-weight: 400;
    line-height: 26px;
  }

  .input {
    &:focus {
      border-color: #414042;
      box-shadow: unset;
    }

    &::placeholder {
      color: #808080;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
  }
}
