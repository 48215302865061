.inputContainer {
  position: relative;
  width: 100%;

  input {
    height: 52px;
    width: 100%;
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
  }

  label {
    position: absolute;
    height: 52px;
    top: 0;
    bottom: 0;
    left: 16px;
    display: flex;
    align-items: center;
    pointer-events: none;
  }

  input,
  label > div {
    font-size: 16px;
    color: #414042;
  }

  label > div {
    transition: all 0.15s ease-out;
    color: grey;
  }

  input:disabled {
    background-color: #e6e7e8;
  }

  input:focus {
    outline: none;
    padding-top: 18px;
    padding-bottom: 2px;
  }

  input:focus + label > div,
  :not(input[value=""]) + label > div {
    transform: translate(0, -12px);
    color: #414042;
    font-size: 10px;
  }

  input:not(:placeholder-shown) {
    padding-top: 16px;
    padding-bottom: 2px;
  }
}

.inputContainerIcon {
  input {
    padding-right: 50px;
  }
}

.inputFieldWrapper {
  position: relative;

  button {
    position: absolute;
    background: transparent;
    border: none;
    top: 50%;
    right: 12px;
    padding: 4px;
    outline: none;
    border-radius: 4px;
    transform: translate(0, -50%);

    &:focus {
      background: #f5f5f5;
    }

    img {
      display: block;
    }
  }
}

.hint {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.3px;
  margin-top: 8px;
}

.error {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.3px;
  margin-top: 8px;
  color: #e53e3e;
}

.closeIcon {
  border: none;
  cursor: pointer;
  position: absolute;
  background: transparent;
  top: 50%;
  right: 12px;
  transform: translate(0, -50%);
}

.datePicker {
  background: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  z-index: 1;
}
