.container {
  position: fixed;
  width: 100%;
  max-width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3a0120;
  padding: 14px 24px;

  @media (min-width: 992px) {
    padding: 20px 80px;
    position: relative;
  }

  .logo {
    width: 56px;

    @media (min-width: 992px) {
      width: 89px;
    }
  }

  .linkWrapper {
    color: #fff;
    font-feature-settings: "salt" on;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    .text {
      @media screen and (max-width: 425px) {
        display: none;
      }
    }

    .link {
      padding-left: 0;
      padding-right: 0;
      color: #fff;
      float: none;
      font-size: 16px;
      line-height: 1;

      span {
        position: relative;
        text-decoration: underline;
        text-transform: initial;
        top: -1px;
      }
    }

    .icon {
      margin-left: 4px;
      margin-bottom: 5px;
    }
  }
}
