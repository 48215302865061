.applyButton {
  align-self: flex-end;
  width: fit-content;
  margin-top: 16px;
}

.responsiveVideo {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.responsiveVideo iframe,
.responsiveVideo object,
.responsiveVideo embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
