.cards {
  background: rgba(0, 0, 0, 0.1);

  .content {
    margin: 24px 0 0;
    display: block;

    @include media-breakpoint-up(lg) {
      margin: 55px 0 0;
      display: flex;
      justify-content: center;
    }
  }

  .card {
    border: 1px solid #e6e7e8;
    border-radius: 8px;
    width: 100%;
    margin: 0 0 24px;
    padding: 19px;

    @include media-breakpoint-up(lg) {
      width: 229px;
      margin: 0 4px;
      padding: 20px 10px;
      min-height: 505px;
    }

    @include media-breakpoint-up(xl) {
      width: 270px;
      min-height: 570px;
      padding: 24px;
    }

    @include media-breakpoint-up(xxl) {
      width: 315px;
      min-height: 550px;
    }

    &:nth-of-type(2) {
      margin: 0;
    }

    &:hover,
    &:focus {
      box-shadow:
        0 24px 38px rgba(0, 0, 0, 0.14),
        0 9px 46px rgba(0, 0, 0, 0.12),
        0 11px 15px rgba(0, 0, 0, 0.2);
    }

    h6 {
      margin-bottom: 7px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 2px;
        font-size: 14px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 15px;
      }
    }

    h3 {
      @include media-breakpoint-up(lg) {
        font-size: 21px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 29px;
      }
    }

    .btn {
      @include media-breakpoint-up(lg) {
        padding: 11px 5px;
        font-size: 12px;
        width: calc(100% - 20px);
      }

      @include media-breakpoint-up(xl) {
        font-size: 14px;
        padding: 11px 10px;
        width: calc(100% - 45px);
      }
    }
  }

  ul {
    margin: 0 0 20px;

    // @include media-breakpoint-up(lg) {
    //   margin: 0 0 93px;
    // }
  }

  button {
    width: 100%;

    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 10px;
      width: calc(100% - 72px);
    }

    @include media-breakpoint-up(xl) {
      bottom: 24px;
    }
  }

  .btn-row {
    float: left;
    width: 100%;
    text-align: center;
    margin: 5px 0 0;

    @include media-breakpoint-up(lg) {
      margin: 50px 0 0;
    }

    .btn {
      position: relative;
      bottom: auto;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 288px;
      }
    }
  }
}
