.register {
  display: flex;
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .centered-layout {
    width: 100%;
    align-items: unset;
  }
}
