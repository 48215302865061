.registerCard {
  width: 100%;
  max-width: 834px;

  @media (max-width: 991px) {
    max-width: 100%;
  }

  .cardHeader {
    display: flex;
    background-color: #fff;
    padding: 24px;
    color: #414042;
    font-weight: 500;
    letter-spacing: normal;
    border-bottom-color: #ccc;

    .backButton {
      @media (max-width: 991px) {
        cursor: pointer;
        background-image: url("../../../../assets/images/backArrow.svg");
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
        background-size: 24px 23px;
        margin-right: 8px;
      }
    }
  }

  .cardBody {
    padding: 24px;
  }

  .cardTitles {
    flex-basis: fit-content;
  }

  .cardTitle {
    color: #1a1a1a;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: normal;
  }

  .subTitle {
    color: #414042;
    letter-spacing: normal;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
}
