.basic-details {
  position: relative;
  background: #efedeb;
  max-width: 834px;
  width: 100%;

  @media (max-width: 991px) {
    position: absolute;
    padding: 0 16px;
    padding-bottom: 16px;
    max-width: 100%;
    min-height: calc(100% - 91px); // header 75px + padding-top 16px
  }
}
