@font-face {
  font-family: plutoSans;
  font-weight: map-get($font-weight, "extraLight");
  src: url("../fonts/PlutoSansExtraLight.ttf");
}

@font-face {
  font-family: plutoSans;
  font-weight: map-get($font-weight, "light");
  src: url("../fonts/PlutoSansLight.ttf");
}

@font-face {
  font-family: plutoSans;
  font-weight: map-get($font-weight, "regular");
  src: url("../fonts/PlutoSansRegular.ttf");
}

@font-face {
  font-family: plutoSans;
  font-weight: map-get($font-weight, "medium");
  src: url("../fonts/PlutoSansMedium.otf");
}

@font-face {
  font-family: plutoSans;
  font-weight: map-get($font-weight, "bold");
  src: url("../fonts/PlutoSansBold.ttf");
}
