.banner {
  background: url("../../assets/images/banner1.png") no-repeat;
  background-position: right calc(100% - 20px);
  height: calc(100% - 44.77px);
  background-size: 70% auto;
  margin-top: 44.77px;
  float: left;
  width: 100%;
  // animation: animatedBackground 1s linear 3ms;


  @include media-breakpoint-up(lg) {
    height: calc(100% - 88px);
    background-size: 57% auto;
    margin: 0;
  }

  @include media-breakpoint-up(xl) {
    background-size: 63% auto;
  }

  @media (min-width: 1400px) {
    background-size: 69% auto;
  }

  @media (min-width: 1450px) {
    background-size: 68% auto;
  }

  @media (min-width: 1600px) {
    background-size: 65% auto;
  }

  &.banner2 {
    background: none;

    .content {
      text-align: center;
    }
  }

  &.banner3 {
    background: none;
  }

  .outer-container {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.38);
    position: relative;

    @include media-breakpoint-between(sm, lg) {
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      background-color: transparent;
    }
  }

  .content {
    color: $white;
    float: left;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin: 235px 0 0;
      text-align: left;
    }
  }

  h2 {
    margin: 10px 0 0;
  }

  .btn-scroll {
    position: absolute;
    left: calc(50% - 24px);
    bottom: 40px;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      bottom: 40px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 110px;
    }
  }

  h5 {
    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 15px;
    }
  }
}

// @keyframes animatedBackground {
//   from {
//     background-position: calc(100% - -950px) calc(100% - 20px);
//   }
//   to {
//     background-position: right calc(100% - 20px);
//   }
// }
