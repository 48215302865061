.section {
  margin-top: 24px;
  max-width: 520px;
  width: 100%;

  .title {
    font-size: 16px;
  }

  .text {
    color: #4d4d4d;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  margin-bottom: 24px;

  button:last-child {
    margin-left: 16px;
  }
}

.agreement {
  display: flex;
  font-size: 14px;

  input {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;
  }

  .agreeText {
    color: #000;
    cursor: pointer;
  }
}

.listItem {
  display: flex;
  margin-top: 16px;
  align-items: center;
  min-height: 26px;

  .listImage {
    display: block;
    margin-right: 16px;
    width: 25px;
    height: 25px;
  }
}

.link {
  display: inline;
  color: #438ded;
  text-decoration: none;
}
