.emailImage {
  max-height: 148px;
  width: fit-content;
}

.contentWrapper {
  margin: 24px 0;
}

.linkButton {
  text-transform: initial;
  padding-right: 0;
}
